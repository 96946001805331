import type { AppProps } from 'next/app'
import { useEffect } from 'react'
import { googleTagManagerId } from '../utils/gtm'
import GoogleTagManager, {
  GoogleTagManagerId,
} from '../components/GoogleTagManager'
import { addHoverEvent } from '../utils/addHoverEvent'

function MyApp({ Component, pageProps, router }: AppProps) {
  useEffect(() => {
    // Processing when hovering to a tag
    addHoverEvent()
  }, [router.pathname])

  return (
    <>
      <GoogleTagManager
        googleTagManagerId={googleTagManagerId as GoogleTagManagerId}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
